import React from "react"

import styles from "./offer.module.scss"
import { PageContext } from "../../../page-context"
import { openOverlay } from "../../../../utils/overlay"

const OfferComponent = ({ title, condition, subcondition, subconditionSmall, showLimitation, tryNowCaption }) => (
  <div className={styles.offer}>
    <div className={`container ${styles.container}`}>
      <h3 className={styles.title}>{title || "Свободный доступ"}</h3>
      <div className={styles.condition}>{condition || <>Мы на вашей стороне:<br/>Параплан дарит вам подписку до конца
        июня</>}</div>
      <div className={subconditionSmall ? "" : styles.description}>
        {subconditionSmall
          ? subcondition
          : <span className={styles.for_free}>{subcondition || ""}</span>}
      </div>
      <PageContext.Consumer>
        {page => (
          <button className={styles.cta}
                  onClick={() => openOverlay(page, "offer", "try")}>
            {tryNowCaption || <>Оформить бесплатный&nbsp;доступ</>}
          </button>)
        }
      </PageContext.Consumer>
      {showLimitation && <div className={styles.due_date}>Предложение действует до 30 июня 2020 года.</div>}
    </div>
  </div>
)

export default OfferComponent
