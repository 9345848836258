import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroFeaturePage from "../components/pages/shared/hero/feature-page"
import TargetAudienceComponent from "../components/pages/main/target-audience"
import AboutComponent from "../components/pages/main/about"
import ReviewComponent from "../components/pages/main/review"
import FeaturesComponent from "../components/pages/main/features"
import PricingComponent from "../components/pages/main/pricing"
import OfferComponent from "../components/pages/main/offer"
import ContactFormComponent from "../components/pages/shared/contact-form"

const IndexPage = (props) => (
  <Layout page={"index"} lang={"de"}>
    <SEO title="CRM-Software für Schulen"
         keywords={[`childcare management software`, `customer data management`]} url={"https://paraplancrm.ru" + props.location.pathname}/>
    <HeroFeaturePage
      name={"index"}
      title='CRM-Software für Schulen'
      description='Schülerinformationen, Unterrichtsplanung, Dokumentenverwaltung. Alles in einem App.'
      tryNowCaption={"Kostenlos anmelden"}
      color='#6D20D2'
      image={require("../images/cover/main.png")}/>
    <TargetAudienceComponent
      title={"Gut geignet für…"}
      childCare={"Privatschulen"}
      languageSchool={"Sprachzentren"}
      kindergarden={"Sportschulen"}
      sportSchool={"Kindergarten"}/>
    <AboutComponent
      title='Die perfekte Software für Schulen.'
      description="Sparen Sie Zeit und Arbeit: Mit Paraplan haben Siw Kurse und Teilnehmer jederzeit im Griff. Von der Kundengewinnung bis zur Zeugnisausgabe kommen Sie mit wenigen Klicks ans Ziel. Mit dem Baukasten-System stellen Sie Ihre optimale Bildungslösung selbst zusammen."
      image={require("../images/screenshot/main_01@2x.en.png")}
      imageWidth={604}
      imageHeight={376}
      color='#E65D49'/>
    <ReviewComponent author='Savr Muchkaev, Sprachschulleiter'>
      Ich mag es, dass wir mit Paraplan Studenten online anmelden können. Ich verbringe weniger Zeit mit der Erstellung
      von Zeitplänen, Abrechnungs- und Verwaltungsarbeiten und bin nicht mit Excel-Dokumenten beschäftigt, um alles zu
      verwalten. Paraplan hat Zeit für jedes Kind und seine Betreuung frei gemacht.
    </ReviewComponent>
    <FeaturesComponent title='Das bietet Ihnen Paraplan:'/>
    <PricingComponent title='Preise' currency='de'/>
    <ContactFormComponent/>
  </Layout>
)

export default IndexPage
